<template>
  <div class="container">
    <div class="c-date">
      {{ dateWeek + " " }} {{ dateYear + " " }}{{ dateDay }}
    </div>
    <div class="c-title">数字院落智能服务平台</div>
    <div class="c-weather">天气 晴 29 °C</div>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";

export default {
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    };
  },
  components: {},
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH:mm");
        this.dateYear = formatTime(new Date(), "yyyy.MM.dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 138px;
  position: relative;
  background: url(../assets/images/topbg.png) no-repeat;
  background-size: 100% 100%;
}
.c-date {
  position: absolute;
  top: 67px;
  left: 172px;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #32c5ff;
}
.c-title {
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translate(-50%, 0);
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bold;
  color: #ffffff;
  font-size: 40px;
}
.c-weather {
  position: absolute;
  top: 67px;
  right: 200px;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #32c5ff;
}
</style>
