<template>
  <div class="chart-cotent">
    <!-- <div class="chart-cotent-box"></div> -->
    <div ref="charts1" class="charts1"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart1: null,
    };
  },
  props: {
    room: {
      type: Array,
      default: () => [{ value: 100, name: "" }],
    },
  },
  watch: {
    room: {
      handler() {
        this.initCharts1();
      },
      deep: true,
      // immediate:true
    },
  },
  mounted() {
    this.initCharts1();
  },

  methods: {
    initCharts1() {
      this.chart1 = this.$echarts.init(this.$refs.charts1);
      let colorList = [
        "rgba(19, 194, 194, 0.85)",
        "rgba(223, 192, 141, 1)",
        "rgba(0, 147, 255, 0.85)",
        "rgba(60, 227, 110, 0.85)",
        "rgba(196, 194, 55, 1)",
      ];
      // let arr = [
      //   {
      //     value: 100,
      //     name: "理发室",
      //   },
      //   { value: 20, name: "球馆" },
      //   { value: 126, name: "健身房" },
      //   { value: 65, name: "棋画室" },
      //   { value: 20, name: "书画室" },
      // ];
      let arr = [];
      this.room.forEach((item) => {
        arr.push({
          value: item.count,
          name: item.roomName,
        });
      });
      let option = {
        grid: {
          top: 0,
          left: "10%",
        },
        series: [
          // 主要展示层的
          {
            radius: ["28%", "56%"],
            center: ["50%", "50%"],
            type: "pie",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            // labelLayout(params) {
            //   return {
            //     x: params.rect.x,
            //     y: params.rect.y,
            //     verticalAlign: "middle",
            //     align: "right",
            //   };
            // },
            labelLine: {
              // showAbove: true,

              normal: {
                show: true,
                length: 15,
                length2: 45,
                lineStyle: {
                  color: "rgba(42, 208, 255, 1)",

                  cap: "square",
                },

                align: "center",
              },
              width: 2,
              color: "#000",
              emphasis: {
                show: true,
              },
            },
            label: {
              normal: {
                formatter: function (params) {
                  let str =
                    "{a|}{nameStyle|" +
                    params.name +
                    "}" +
                    "\n{rate|" +
                    params.value +
                    "}";
                  // switch (params.name) {
                  //   case "球馆":
                  //     str =
                  //       "{a|}{nameStyle|" +
                  //       params.name +
                  //       "}" +
                  //       "\n{rate1|" +
                  //       params.value +
                  //       "}";
                  //     break;
                  //   case "健身房":
                  //     str =
                  //       "{a|}{nameStyle1|" +
                  //       params.name +
                  //       "}" +
                  //       "\n{rate2|" +
                  //       params.value +
                  //       "}";
                  //     break;
                  // }

                  return str;
                },
                padding: [0, -40],
                height: 80,

                // width: 200,
                rich: {
                  a: {
                    align: "center",
                  },
                  nameStyle: {
                    fontSize: 14,
                    color: "#fff",
                  },
                  nameStyle1: {
                    fontSize: 14,
                    color: "#fff",
                    width: 100,
                    align: "center",
                  },
                  rate: {
                    fontSize: 14,
                    height: 20,
                    lineHeight: 20,
                    color: "#2AD0FF",
                    align: "center",
                  },
                  rate1: {
                    fontSize: 14,
                    height: 20,
                    width: 100,
                    lineHeight: 20,
                    color: "#2AD0FF",
                    align: "center",
                  },
                  rate2: {
                    fontSize: 14,
                    height: 20,
                    width: 100,
                    paddingRight: 100,
                    lineHeight: 20,
                    color: "#2AD0FF",
                    align: "center",
                  },
                },
              },
            },
            data: arr,
          },
          {
            type: "pie",
            radius: ["30%", "22%"],
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                color: "rgba(15, 65, 122, 0.85)",
              },
            },
            data: [100],
            label: {
              nomal: {
                show: false,
              },
            },
          },
        ],
      };
      this.chart1.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-cotent {
  width: 220px;
  height: 100%;
  border-radius: 50%;
  background: #0f376e;
  box-shadow: inset 0px 0px 14px 0px #2ad0ff;
  position: absolute;
  top: 68px;
  left: 160px;
  background-color: #2ad0ff;
  .chart-cotent-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #0f376e;
    box-shadow: inset 0px 0px 12px 0px #2ad0ff;
    position: absolute;
    top: 70px;
    left: 70px;
  }
}
.charts1 {
  width: 300px;
  height: 260px;
  position: absolute;
  top: -20px;
  left: -40px;
  z-index: 999;
}
</style>