<template>
  <div class="chart-cotent">
    <!-- <div class="chart-cotent-box"></div> -->
    <div ref="charts1" class="charts1"></div>
    <div class="ra">
      <div>{{ num }}</div>
      <div>次</div>
    </div>
    <div class="rc">
      <div>{{ title }}</div>
    </div>
    <div class="rb"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart1: null,
    };
  },
  watch: {
    precent: {
      handler() {
        this.initCharts1();
      },
    },
  },
  props: {
    num: {
      type: Number,
      default() {
        return 0;
      },
    },
    title: {
      type: String,
      default() {
        return "0";
      },
    },
    precent: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  mounted() {
    this.initCharts1();
  },

  methods: {
    initCharts1() {
      this.chart1 = this.$echarts.init(this.$refs.charts1);
      let value = this.precent;

      let option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "内部阴影",
            type: "gauge",
            // center: ['20%', '50%'],
            radius: "70%",
            z: 4,
            max: 200,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    value / 200,
                    this.$echarts.graphic.LinearGradient(0, 0, 0, 4, [
                      {
                        offset: 0,
                        color: "rgba(5, 92, 208,1)",
                      },

                      {
                        offset: 1,
                        color: "rgba(5, 92, 208,0)",
                      },
                    ]),
                  ],
                  [1, "rgba(145,207,255,0)"],
                ],
                width: 36,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
              formatter: function (value) {
                if (value !== 0) {
                  return parseInt(value);
                } else {
                  return 0;
                }
              },
              offsetCenter: [0, 5],
              textStyle: {
                padding: [0, 0, 0, 0],
                fontSize: 18,
                color: "#EDFFFD",
              },
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: "title",
                value: 100,
              },
            ],
            pointer: {
              show: false,
            },
          },

          {
            name: "内部圈",
            type: "gauge",
            z: 2,
            min: 0,
            max: 200,
            splitNumber: 10,
            radius: "70%",
            axisLine: {
              lineStyle: {
                color: [[1, "#016dde"]],
                width: 3,
                shadowColor: "rgba(1, 78, 181,.5)",
                shadowBlur: 6,
                shadowOffsetX: 0,
              },
            },
            tooltip: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: "title",
                value: value,
              },
            ],

            pointer: {
              show: true,
              length: "80%",
              radius: "20%",
              width: 3, //指针粗细
            },
            animationDuration: 4000,
          },
          {
            name: "内部刻度",
            type: "gauge",
            radius: "72%",
            z: 99,
            min: 0, //最小刻度
            max: 200, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 230,
            endAngle: -45,
            axisLine: {
              show: false,
              lineStyle: {
                width: 5,
                color: [[1, "#1087e2"]],
              },
            }, //仪表盘轴线
            axisLabel: {
              show: true,
              formatter: function (params) {
                let str = `{a|${params}}`;

                if (params / 200 > value / 200) {
                  str = `{b|${params}}`;
                }
                return str;
              },
              distance: 2,
              fontSize: 12,
              rich: {
                a: {
                  color: "#fff",
                },
                b: {
                  color: "rgba(255,255,255,.5)",
                },
              },
            }, //刻度标签。
            label: {},

            axisTick: {
              show: true,
              splitNumber: 7,
              lineStyle: {
                color: "#fff", //用颜色渐变函数不起作用
                width: 1,
              },
              length: 4,
            }, //刻度样式
            splitLine: {
              show: true,
              length: 8,
              lineStyle: {
                color: "#1C3164", //用颜色渐变函数不起作用
              },
            }, //分隔线样式
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
          {
            //指针上的圆
            type: "pie",
            tooltip: {
              show: false,
            },
            hoverAnimation: false,
            legendHoverLink: false,
            radius: ["0%", "20%"],
            center: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: value,
                itemStyle: {
                  normal: {
                    color: "rgb(14,31,73)",
                  },
                },
              },
            ],
          },
        ],
      };

      this.chart1.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-cotent {
  width: 258px;
  height: 251px;
  position: relative;
}
.charts1 {
  width: 258px;
  height: 251px;
}
.ra {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // box-shadow: inset 0px 0px 14px 0px #083be9;
  top: 50%;
  left: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 9999;
  div {
    &:nth-child(1) {
      font-size: 26px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #ffffff;
    }
    &:nth-child(2) {
      font-size: 12px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #ffffff;
    }
  }

  background-color: #011067;
}
.rc {
  position: absolute;

  height: 60px;

  // box-shadow: inset 0px 0px 14px 0px #083be9;
  top: 75%;
  left: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 9999;
  div {
    &:nth-child(1) {
      font-size: 13px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #ffffff;
    }
    &:nth-child(2) {
      font-size: 12px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.rb {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 1;

  top: 50%;
  left: 50%;
  text-align: center;
  display: flex;
  transform: translate(-50%, -50%);
}
</style>