import request from '@/utils/request'
// 院落用户反馈分析
export function courtYardMemberData(params) {
  return request({
    url: '/screen/courtYardMemberData',
    method: 'get',
    params
  })
}
// 访客预约
export function visitorRecord(params) {
  return request({
    url: '/screen/visitorRecord',
    method: 'get',
    params
  })
}
// 一键呼叫
export function callRecord(params) {
  return request({
    url: '/screen/callRecord',
    method: 'get',
    params
  })
}
// 一键呼叫
export function activityData(year) {
  return request({
    url: `/screen/activityData/${year}`,
    method: 'get',
  })
}
export function activityDataclick(courtYardId) {
  return request({
    url: `screen/activityDataWithCourtYardId/${courtYardId}`,
    method: 'get',
  })
}
export function serviceData(params) {
  return request({
    url: `/screen/serviceData`,
    method: 'get',
    params
  })
}
export function serviceDataWithMonth(params) {
  return request({
    url: `/screen/serviceDataByMonth`,
    method: 'get',
    params
  })
}