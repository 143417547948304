<template>
  <div class="chart-cotent">
    <div ref="charts1" class="charts1"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart1: null,
    };
  },
  props: {
    month: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.initCharts1();
  },

  methods: {
    initCharts1() {
      this.chart1 = this.$echarts.init(this.$refs.charts1);
      let xdata = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
      let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let key in this.month) {
        switch (key) {
          case "一月":
            arr[0] = this.month[key];
            break;
          case "二月":
            arr[1] = this.month[key];
            break;
          case "三月":
            arr[2] = this.month[key];
            break;
          case "四月":
            arr[3] = this.month[key];
            break;
          case "五月":
            arr[4] = this.month[key];
            break;
          case "六月":
            arr[5] = this.month[key];
            break;
          case "七月":
            arr[6] = this.month[key];
            break;
          case "八月":
            arr[7] = this.month[key];
            break;
          case "九月":
            arr[8] = this.month[key];
            break;
          case "十月":
            arr[9] = this.month[key];
            break;
          case "十一月":
            arr[10] = this.month[key];
            break;
          case "十二月":
            arr[11] = this.month[key];
            break;
        }
      }

      let option = {
        grid: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 22,
          containLabel: true,
        },
        tooltip: {
          //提示框组件
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            color: "#ffffff",
            interval: 0,
          },
          alignTicks: true,

          splitLine: {
            show: true,

            lineStyle: {
              color: "#66FFFF",
              // type: "dashed",
            },
          },
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.45)",
              // width: 1.5,
            },
          },
          data: xdata,
        },
        yAxis: {
          type: "value",
          // splitNumber: 4,
          axisLabel: {
            color: "#ffffff",
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "rgba(42, 236, 255, 0.44)",
              width: 1.5,
            },
          },
          splitLine: {
            show: false,

            lineStyle: {
              color: "rgba(17,110,129,0.36)",
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: arr,
            type: "line",
            symbol: "none",
            smooth: true,
            lineStyle: {
              color: "#00F2EF",
              width: 6,
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(11, 28, 157, 1)",
                },
                {
                  offset: 0.5,
                  color: "rgba(14, 95, 255, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(2, 0, 77, 1)",
                },
              ]),
            },
          },
        ],
      };

      this.chart1.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-cotent {
  width: 100%;
  height: 220px;
}
.charts1 {
  width: 100%;
  height: 220px;

  // background: linear-gradient(
  //   180deg,
  //   rgba(28, 0, 255, 0.92) 0%,
  //   rgba(28, 0, 255, 0) 100%
  // );
  border-image: linear-gradient(
      180deg,
      rgba(0, 242, 239, 1),
      rgba(14, 95, 255, 1)
    )
    5 5;
}
</style>