<template>
  <div id="center">
    <div class="c-top">
      <div class="t-title">院落服务统计</div>
      <div class="t-center">
        <div class="c-left">
          <gaugeChart
            title="待处理总数"
            ref="gaugeChart1"
            :num="num"
            :precent="precent"
          />
        </div>
        <div class="c-center">
          <div class="tj">
            <div
              class="tj-son"
              :class="{ active: active == i }"
              v-for="(item, i) in activeList"
              :key="i"
              @click="doClick(i)"
            >
              <span>{{ item.label }}</span> <span>{{ item.num }}</span>
            </div>
          </div>
        </div>
        <div class="c-right">
          <gaugeChart title="今日新增" :num="num1" :precent="precent1" />
        </div>
      </div>
    </div>
    <div class="c-bt">
      <div class="b-title">服务分布统计（月）</div>
      <div class="b-center">
        <div class="b-num">
          <div class="n-t">
            <span>服务总数</span>
            <span>环比</span>
            <span>+{{ addNum }}%</span>
          </div>
          <div class="n-num">
            <span class="n-bg" v-for="(item, i) in numArr" :key="i">
              {{ item }}</span
            >
            <span class="n-c">次</span>
          </div>
        </div>
        <div class="b-tj">
          <div class="b-type" v-for="(item, i) in numList" :key="i">
            <div class="t-num">{{ item.count }}</div>
            <div class="t-img"></div>
            <div class="t-label">{{ item.courtYardName }}</div>
          </div>
        </div>
      </div>
      <div class="z-charts">
        <barChart :numList="numList"></barChart>
      </div>
    </div>
    <!-- 柱状图区域 -->
  </div>
</template>

<script>
import barChart from "@/components/echart/barCharts.vue";
import gaugeChart from "@/components/echart/gaugeCharts.vue";
import { serviceData, serviceDataWithMonth } from "@/api/index";

export default {
  data() {
    return {
      active: 1,
      numArr: [0],
      numList: [],
      ylList: [],
      addNum: 0,
      typeList: [
        {
          label: "家电清洁",
          num: "0",
        },
        {
          label: "清洁保养",
          num: "0",
        },
        {
          label: "日常维修",
          num: "0",
        },

        {
          label: "活动保障",
          num: "0",
        },
        {
          label: "物品保存",
          num: "0",
        },
      ],
      detail: {},
      num: 0,
      precent: 0,
      num1: 0,
      precent1: 0,
      activeList: [
        {
          label: "清洁保养",
          num: "567",
        },
        {
          label: "日常维修",
          num: "782",
        },
        {
          label: "家电清洁",
          num: "234",
        },
      ],
      water: {},
      // 通过率和达标率的组件复用数据
    };
  },
  components: { barChart, gaugeChart },
  mounted() {
    this.getServiceData();
    this.getServiceDataWithMonth();
  },
  methods: {
    async getServiceData() {
      const res = await serviceData();
      this.detail = res.data;
      this.activeList[2].num = res.data.applianceCleaning.total;
      this.activeList[1].num = res.data.dailyRepair.total;
      this.activeList[0].num = res.data.dailyCleaning.total;
      this.num = res.data.dailyRepair.dealTotal;
      this.precent =
        ((this.num / res.data.dailyRepair.total) * 200).toFixed(2) - 0;

      this.num1 = res.data.dailyRepair.todayTotal;
      this.precent1 =
        ((this.num1 / res.data.dailyRepair.total) * 200).toFixed(2) - 0;
    },
    async getServiceDataWithMonth() {
      const res = await serviceDataWithMonth();
      this.numArr = res.data.total.toString().split("");
      this.addNum = res.data.percentage;
      this.numList = res.data.courtYards || [];

      // this.numList.push(arr1, arr2, arr3, arr4);
    },
    doClick(i) {
      this.active = i;
      if (this.active == 0) {
        this.num = this.detail.dailyCleaning.dealTotal;
        this.precent =
          ((this.num / this.detail.dailyCleaning.total) * 200).toFixed(2) - 0;

        this.num1 = this.detail.dailyCleaning.todayTotal;
        this.precent1 =
          ((this.num1 / this.detail.dailyCleaning.total) * 200).toFixed(2) - 0;
      } else if (this.active == 1) {
        this.num = this.detail.dailyRepair.dealTotal;
        this.precent =
          ((this.num / this.detail.dailyRepair.total) * 200).toFixed(2) - 0;

        this.num1 = this.detail.dailyRepair.todayTotal;
        this.precent1 =
          ((this.num1 / this.detail.dailyRepair.total) * 200).toFixed(2) - 0;
      } else {
        this.num = this.detail.applianceCleaning.dealTotal;
        this.precent =
          ((this.num / this.detail.applianceCleaning.total) * 200).toFixed(2) -
          0;

        this.num1 = this.detail.applianceCleaning.todayTotal;
        this.precent1 =
          ((this.num1 / this.detail.applianceCleaning.total) * 200).toFixed(2) -
          0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  width: 921px;
  padding: 0 19px;

  // height: 100%;

  .c-top {
    width: 100%;
    height: 395px;

    background: url(../assets/images/ybpbg.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    .t-title {
      font-size: 14px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #19ecff;
      line-height: 30px;
      width: 100%;
      height: 30px;
      text-align: center;
    }
    .t-center {
      position: relative;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 283px;
      width: 100%;
      // margin-top: 32px;
      .c-left,
      .c-right {
        width: 258px;
        height: 251px;
        background: url(../assets/images/gaugebg.png) no-repeat;
        background-size: 100% 100%;
      }
      .c-center {
        width: 636px;
        height: 283px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url(../assets/images/c1.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .tj {
          cursor: pointer;
          .tj-son {
            width: 296px;
            text-align: center;
            height: 69px;
            line-height: 69px;

            span {
              &:nth-child(1) {
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #7c7ba2;
              }
              &:nth-child(2) {
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #3077a0;
              }
            }
          }
          .active {
            background: url(../assets/images/c3.png) no-repeat;
            background-size: 100% 100%;
            span {
              &:nth-child(1) {
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #fff !important;
              }
              &:nth-child(2) {
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #62f6f9 !important;
              }
            }
          }
        }
      }
    }
  }
  .c-bt {
    width: 100%;
    height: 921px;
    background: url(../assets/images/servebg.png) no-repeat;
    background-size: 100% 100%;
    .b-title {
      font-size: 14px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #19ecff;
      line-height: 40px;
      width: 100%;
      height: 40px;
      text-align: center;
    }
    .b-center {
      height: 120px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .b-num {
        margin-left: 59px;
        .n-t {
          display: flex;
          span {
            height: 18px;

            &:nth-child(1) {
              font-size: 18px;
              line-height: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }
            &:nth-child(2) {
              font-size: 12px;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;
              color: #ffffff;
              line-height: 20px;
              margin-left: 5px;
            }
            &:nth-child(3) {
              font-size: 14px;
              font-family: Helvetica;
              color: #fedb65;
              line-height: 20px;
            }
          }
        }
        .n-num {
          display: flex;
          margin-top: 8px;
          .n-bg {
            margin-right: 5px;
            width: 51px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 38px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #66ffff;
            background: url(../assets/images/c4.png) no-repeat;
            background-size: 100% 100%;
          }
          .n-c {
            margin-left: 15px;
            width: 51px;
            height: 60px;
            line-height: 60px;
            text-align: center;

            font-size: 38px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #66ffff;
            background: url(../assets/images/c4.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .b-tj {
        margin-right: 28px;
        display: flex;

        .b-type {
          display: flex;
          width: 92px;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .t-num {
            font-size: 18px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #66ffff;
          }
          .t-img {
            width: 44px;
            height: 52px;
            background: url(../assets/images/c5.png) no-repeat;
            background-size: 100% 100%;
            margin: 12px 0 9px 0;
          }
          .t-label {
            font-size: 14px;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    .z-charts {
      margin-top: 27px;
    }
  }
}
</style>
