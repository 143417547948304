<template>
  <div class="chart-cotent">
    <div ref="charts1" class="charts1"></div>
    <div class="line-box">
      <div class="line">
        <div v-for="(item, i) in xdata" :key="i">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="address">
      <div class="address-c" v-for="(item, i) in color" :key="i">
        <span :style="{ 'background-color': item.value }"></span>
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart1: null,
      xdata: [],
      color: [
        // { label: "5号院", value: "#2DFF6B", data: [1, 2, 3, 4, 5] },
      ],
    };
  },
  mounted() {
    this.initCharts1();
  },
  props: {
    numList: {
      type: Array,
      dafault: () => [],
    },
  },
  watch: {
    numList: {
      handler() {
        this.initCharts1();
      },
    },
  },
  methods: {
    initCharts1() {
      this.chart1 = this.$echarts.init(this.$refs.charts1);
      this.xdata = [];
      let series = [];

      if (this.numList.length > 0) {
        for (let key in this.numList[0].dataMap) {
          this.xdata.push(key);
        }
        this.numList.forEach((item, i) => {
          let y = [];
          for (let key in item.dataMap) {
            y.push(item.dataMap[key]);
          }

          switch (i) {
            case 0:
              this.color.push({
                label: item.courtYardName,
                value: "#2D69FF",
                data: y,
              });
              break;
            case 1:
              this.color.push({
                label: item.courtYardName,
                value: "#2DA6FF",
                data: y,
              });
              break;
            case 2:
              this.color.push({
                label: item.courtYardName,
                value: "#2DF3FF",
                data: y,
              });
              break;
            case 3:
              this.color.push({
                label: item.courtYardName,
                value: "#2DFF6B",
                data: y,
              });
              break;
            case 4:
              this.color.push({
                label: item.courtYardName,
                value: "#2D69FF",
                data: y,
              });
              break;
            default:
              this.color.push({
                label: item.courtYardName,
                value: "#2D69FF",
                data: y,
              });
          }
        });
        this.color.forEach((item) => {
          series.push({
            name: item.label,
            type: "bar",
            barGap: 0.5,

            itemStyle: {
              color: item.value,
              shadowOffsetX: 0,
            },
            barWidth: 10,
            data: item.data,
          });
        });
      }

      let option = {
        tooltip: {
          trigger: "item",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            let str = `<div style="width:45px;border-radius: 2px;text-align:center;z-index:9999; background-color:${params.color};font-size: 12px;
               font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;color:#fff" >${params.value}</div>`;
            return str;
          },
          padding: 0,
          position: "top",
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "0",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.xdata,
            axisLine: {
              show: false,
              margin: -100,
            },
            axisLabel: {
              color: "#ffffff",
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: false,
              formatter: "{value}",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,

              lineStyle: {
                color: "rgba(17,110,129,0.36)",
                type: "dashed",
              },
            },
          },
        ],
        series,
      };

      this.chart1.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-cotent {
  width: 100%;
  height: 360px;
}
.charts1 {
  width: 100%;
  height: 280px;

  // background: linear-gradient(
  //   180deg,
  //   rgba(28, 0, 255, 0.92) 0%,
  //   rgba(28, 0, 255, 0) 100%
  // );
  border-image: linear-gradient(
      180deg,
      rgba(0, 242, 239, 1),
      rgba(14, 95, 255, 1)
    )
    5 5;
}
.line-box {
  width: 100%;
  padding: 0 20px;
  margin-top: 10px;
}
.line {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.39);
  height: 20px;
  display: flex;
  font-size: 12px;

  justify-content: space-around;
}
.address {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .address-c {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 51px;
    span {
      &:nth-child(1) {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 5px;
      }
      &:nth-child(2) {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
</style>