<template>
  <div class="container">
    <div class="c-title">活动保障分析</div>
    <div class="c-top">
      <ul>
        <li
          v-for="(item, i) in courtYard"
          @click="doChaget(item)"
          :class="{ acbg: id == item.courtYardId }"
          :key="i"
        >
          <div class="num">{{ item.count }}次</div>
          <div class="address">{{ item.courtYardName }}</div>
        </li>
      </ul>
    </div>
    <div class="active">
      <div class="a-img"></div>
      <div class="a-text">活动预约情况</div>
    </div>
    <div class="charts-area">
      <div class="allnum">
        <div class="n-text">预约总数</div>
        <div class="n-num">{{ allNum }}次</div>
      </div>
      <!-- 饼图区域 -->
      <pineChart ref="pine" :room="room" v-if="room"></pineChart>
    </div>
    <div class="active">
      <div class="a-img"></div>
      <div class="a-text">活动预约分析</div>
    </div>
    <div class="line-area">
      <!-- 折线图区域 -->
      <lineChart :month="month" v-if="month"></lineChart>
    </div>
  </div>
</template>

<script>
import { activityData, activityDataclick } from "@/api/index";

import pineChart from "@/components/echart/pineCharts.vue";
import lineChart from "@/components/echart/lineCharts.vue";
import { formatTime } from "../utils/index.js";

export default {
  data() {
    return {
      chart1: null,
      dateYear: null,
      month: null,
      room: null,
      courtYard: [],
      allNum: 0,
      id: "",
    };
  },
  components: { pineChart, lineChart },
  mounted() {
    this.getActivityData();
  },

  methods: {
    doChaget(item) {
      this.id = item.courtYardId;
      this.getactivityDataclick(this.id);
    },
    async getActivityData() {
      this.dateYear = formatTime(new Date(), "yyyy");
      const res = await activityData(this.dateYear);
      this.month = res.data.monthData;
      this.courtYard = res.data.courtYard;
      this.allNum = res.data.all;
      this.id = this.courtYard[0].courtYardId;
      this.getactivityDataclick(this.id);
    },
    async getactivityDataclick(id) {
      const data = await activityDataclick(id);
      this.room = data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.acbg {
  background-color: #174289 !important;
}
.container {
  width: 441px;
  height: 100%;
  background: url(../assets/images/leftbg.png) no-repeat;
  background-size: 100% 100%;
  .c-title {
    height: 34px;
    width: 100%;
    text-align: center;
    line-height: 34px;
  }
  .c-top {
    padding: 10px;
    ul {
      width: 100%;
      overflow: auto;
      height: 220px;
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 10px;
        width: 110px;
        height: 73px;
        background: url(../assets/images/numbg.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        flex-direction: column;

        .num {
          font-size: 20px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #6dffff;
          line-height: 22px;
        }
        .address {
          font-size: 12px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #ffffff;
          margin-top: 5px;
        }
      }
    }
  }
  .active {
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 21px;
    width: 100%;
    margin-top: 10px;
    .a-img {
      width: 23px;
      height: 21px;
      background: url(../assets/images/active.png) no-repeat;
      background-size: 100% 100%;
      margin-right: 12px;
    }
  }
}
.charts-area {
  position: relative;
  height: 300px;
  padding: 0 20px;
  margin-top: 26px;
  .allnum {
    width: 173px;
    height: 86px;
    background: url(../assets/images/allnumbg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .n-text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #cfdcff;
      position: absolute;
      top: 17px;
      left: 17px;
    }
    .n-num {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2ad0ff;
      position: absolute;
      left: 65px;
      top: 43px;
    }
  }
}
</style>
