<template>
  <!-- <div id="index" :style="{ height: height + 'px' }" ref="appRef"> -->
  <div id="index" ref="appRef">
    <top></top>
    <div class="main">
      <left></left>
      <center></center>
      <right></right>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin"
import { formatTime } from "../utils/index.js"
import Top from "./top.vue"
import Left from "./left.vue"
import Center from "./center.vue"
import Right from "./right.vue"
export default {
  mixins: [drawMixin],
  data () {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      height: 0,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    }
  },
  components: {
    Top,
    Left,
    Center,
    Right,
  },
  mounted () {
    this.height = window.innerHeight
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn () {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss")
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd")
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
@import "../assets/scss/style.scss";
.main {
  height: calc(100% - 180px);
  display: flex;
  justify-content: center;
  padding: 0 40px;
  margin-top: 7px;
}

#index {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #0b1c9d 0%, #02004d 18%, #02004d 100%);
  overflow: hidden;
}
</style>
