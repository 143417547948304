<template>
  <div class="container">
    <div class="r-t">
      <div class="t-title">清洁保养数据分析</div>
      <div class="b-center">
        <div class="b-num">
          <div class="n-t">
            <span>服务次数</span>
          </div>
          <div class="n-num">
            <span class="n-bg" v-for="(item, i) in numArr" :key="i">
              {{ item }}</span
            >
            <span class="n-c">户</span>
          </div>
        </div>
        <div class="b-num">
          <div class="n-t">
            <span>院落数</span>
          </div>
          <div class="n-num">
            <span class="n-bg" v-for="(item, i) in numArr1" :key="i">
              {{ item }}</span
            >
            <span class="n-bg">个</span>
          </div>
        </div>
      </div>
      <div class="pf">
        <div class="pf-item" v-for="(item, i) in precent" :key="i">
          <span>{{ item.courtYardName }}</span>
          <span>{{ item.count }}次</span>
        </div>
      </div>
    </div>
    <div class="r-c">
      <div class="t-title">访客预约</div>
      <dv-scroll-board class="dv-scr-board" ref="scoll1" :config="config" />
    </div>
    <div class="r-b">
      <div class="t-title b-t">一键呼叫</div>
      <div class="r-b-t">
        <div class="hj">
          <div class="hj-num">{{ callSOS }}次</div>
          <div class="hj-bj">呼叫报警</div>
        </div>
        <div class="hj bj">
          <div class="hj-num">{{ callHealth }}次</div>
          <div class="hj-bj">医务报警</div>
        </div>
      </div>
      <dv-scroll-board
        @click="doClick"
        class="dv-scr-board2"
        :config="config2"
      />
    </div>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
import { courtYardMemberData, visitorRecord, callRecord } from "@/api/index";

export default {
  data() {
    return {
      precent: [
        // {
        //   name: "11号院",
        //   value: "67",
        // },
        // {
        //   name: "70号院",
        //   value: "45",
        // },
        // {
        //   name: "欣宛",
        //   value: "38",
        // },
        // {
        //   name: "雅宛",
        //   value: "45",
        // },
        // {
        //   name: "保椒院",
        //   value: "45",
        // },
      ],
      callSOS: 0,
      callHealth: 0,
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      numArr: [0],
      numArr1: [0],
      config: {
        header: ["序号", "访客姓名", "所访院落", "来访时间"],
        data: [],
      },
      config2: {
        header: ["序号", "用户", "类型", "时间"],
        data: [],
        rowNum: 5, //表格行数
        headerHeight: 40,
        headerBGC: "", //表头
        oddRowBGC: "rgba(14, 95, 255, 0.2)", //奇数行
        evenRowBGC: "rgba(14, 95, 255, 0.3)", //偶数行
        index: false,
        columnWidth: [80, 120, 120, 200],
        align: ["center", "center", "center", "left"],
      },
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    };
  },
  components: {},
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.getcourtYardMemberData();
    this.getVisitorRecord();
    this.getCallRecord();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    async getVisitorRecord() {
      const res = await visitorRecord({ pageSize: 999, pageNum: 1 });
      let arr = [];
      if (res.rows && res.rows.length > 0) {
        res.rows.forEach((item, i) => {
          let num = i + 1;
          if (num <= 9) {
            num = "0" + num;
          } else {
            num = num + "";
          }

          arr.push([num, item.visitorName, item.courtYardName, item.startTime]);
        });
        this.config = {
          header: ["序号", "访客姓名", "所访院落", "来访时间"],
          data: arr,
          rowNum: 5, //表格行数
          headerHeight: 40,
          headerBGC: "", //表头
          oddRowBGC: "rgba(14, 95, 255, 0.2)", //奇数行
          evenRowBGC: "rgba(14, 95, 255, 0.3)", //偶数行
          index: false,
          columnWidth: [80, 120, 120, 200],
          align: ["center", "center", "center", "left"],
        };
      }
    },
    dealTel(val) {
      if (!val) return "***";
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return val.replace(reg, "$1****$2");
    },
    async getCallRecord() {
      const res = await callRecord({ pageSize: 999, pageNum: 1 });
      let arr = [];
      if (res.rows && res.rows.length > 0) {
        res.rows.forEach((item, i) => {
          let num = i + 1;
          if (num <= 9) {
            num = "0" + num;
          } else {
            num = num + "";
          }
          // let name = item.nickname + " ";
          let name = this.dealTel(item.username);
          let type = "";
          if (item.callType == "2") {
            type = `<span style="color:#66FFFF" >${item.courtYardName}<span>`;
          } else {
            type = `<span style="color:#0ADB8B" >${item.courtYardName}<span>`;
          }
          arr.push([num, name, type, item.createTime]);
        });
        this.config2 = {
          header: ["序号", "用户", "类型", "时间"],
          data: arr,
          rowNum: 5, //表格行数
          headerHeight: 40,
          headerBGC: "", //表头
          oddRowBGC: "rgba(14, 95, 255, 0.2)", //奇数行
          evenRowBGC: "rgba(14, 95, 255, 0.3)", //偶数行
          index: false,
          columnWidth: [80, 180, 120, 240],
          align: ["center", "center", "center", "left"],
        };
      }
    },
    async getcourtYardMemberData() {
      const { data } = await courtYardMemberData();
      this.numArr = data.member.toString().split("");
      this.numArr1 = data.courtYard.toString().split("");
      this.callSOS = data.callSOS;
      this.callHealth = data.callHealth;
      this.precent = data.courtYards;
    },
    doClick(row) {
      console.log(row);
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 440px;
  height: 100%;
  overflow: hidden;

  .dv-scr-board {
    width: 100%;
    height: 200px;
    margin-top: 30px;
    padding: 0 20px;
    cursor: pointer;
  }
  .dv-scr-board2 {
    width: 100%;
    height: 200px;
    cursor: pointer;
  }
  .r-t {
    width: 100%;
    height: 229px;
    background: url(../assets/images/r1.png) no-repeat;
    background-size: 100% 100%;
    .b-center {
      height: 120px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      padding-right: 40px;
      .b-num {
        .n-t {
          display: flex;
          span {
            height: 18px;

            &:nth-child(1) {
              font-size: 18px;
              line-height: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }
            &:nth-child(2) {
              font-size: 12px;
              font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
              font-weight: 800;
              color: #ffffff;
              line-height: 20px;
              margin-left: 5px;
            }
            &:nth-child(3) {
              font-size: 14px;
              font-family: Helvetica;
              color: #fedb65;
              line-height: 20px;
            }
          }
        }
        .n-num {
          display: flex;
          margin-top: 8px;
          .n-bg {
            margin-right: 5px;
            width: 51px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 38px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #66ffff;
            background: url(../assets/images/c4.png) no-repeat;
            background-size: 100% 100%;
          }
          .n-c {
            margin-left: 15px;
            width: 51px;
            height: 60px;
            line-height: 60px;
            text-align: center;

            font-size: 38px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #66ffff;
            background: url(../assets/images/c4.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .pf {
      display: flex;
      justify-content: flex-start;

      flex-wrap: wrap;
      width: 100%;
      padding: 0 10px;
      height: 60px;
      overflow: auto;

      .pf-item {
        width: 33%;
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        span {
          &:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #0bc78f;
            margin-right: 30px;
          }
          &:nth-child(2) {
            font-size: 18px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #66ffff;
          }
        }
      }
    }
  }
  .r-c {
    width: 100%;
    height: 279px;
    margin: 10px 0;
    background: url(../assets/images/r1.png) no-repeat;
    background-size: 100% 100%;
  }
  .r-b {
    width: 100%;
    height: 372px;
    padding: 0 20px;
    background: url(../assets/images/r1.png) no-repeat;
    background-size: 100% 100%;
  }
}
.t-title {
  font-size: 14px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #19ecff;
  line-height: 30px;
  width: 100%;
  height: 30px;
  text-align: center;
}
.b-t {
  height: 60px !important;
  line-height: 60px !important;
}
.r-b-t {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  height: auto;
  .bg {
    margin-right: 0 !important;
    margin-left: 10px !important;
  }
  .hj {
    width: 440px;
    margin-right: 10px;
    .hj-num {
      font-size: 27px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      text-align: center;
      color: #66ffff;
    }
    .hj-bj {
      width: 200px;
      height: 61px;
      text-align: center;
      line-height: 73px;
      background: url(../assets/images/r2.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
